import { userProfileService } from "@spx-workflow/utility/user-profile";
import { navigateToUrl, registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructLayoutEngine,
  constructRoutes,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
const ROUTES = {
  ROOT: "/",
  LOGIN: "/login",
  LICENSE: "/license",
  HOME: "/dashboard/view/default",
  HOME_COMMON: "/main",
  COMMON: "/common/"
};

const keyParamRedirect = "redirect";
const keyDashboardView = "dashboard/view/";
const protectAuthentication = (authenticated: boolean) => {
  
  if (!authenticated && !location.pathname.startsWith(ROUTES.LOGIN) && !location.pathname.includes(ROUTES.LICENSE)) {
    navigateToUrl(`${ROUTES.LOGIN}?${keyParamRedirect}=${location.pathname}`);
  }

  //In case not be admin role that access to license screen
  if ((authenticated && userProfileService?.current_role != undefined
     && userProfileService?.current_role != 'admin' && location.pathname.includes(ROUTES.LICENSE))
    || (!authenticated && location.pathname.includes(ROUTES.LICENSE))) {
    navigateToUrl(`${ROUTES.LOGIN}`);
  }

  if (
    authenticated &&
    (location.pathname.startsWith(ROUTES.LOGIN) ||
      location.pathname === ROUTES.ROOT)
  ) {
    const urlParams = new URLSearchParams(window.location.search);
    let url = Object.fromEntries(urlParams.entries())[keyParamRedirect];
    // check case redirect home with each user login.
    if(url && url.includes(keyDashboardView)){
      let dashboardId = url.split(keyDashboardView)[1];
      let dashboard = userProfileService?.listDashboards?.filter((x: { id: string; }) => x.id === dashboardId)
      if (dashboard?.length === 0){
        url = ROUTES.HOME
      }
    }
    let redirectUrl = url|| ROUTES.HOME;
    if (redirectUrl.indexOf(ROUTES.COMMON) > -1) {
      redirectUrl = ROUTES.HOME;
    }
    navigateToUrl(redirectUrl);
  }
};
await userProfileService.loadUserProfile().toPromise();
userProfileService.authenticated.subscribe((authenticated: boolean) => {
  protectAuthentication(authenticated);
});
protectAuthentication(userProfileService.isAuthenticated);
const routes = constructRoutes(microfrontendLayout, {
  loaders: {
    componentloader: `<div class='placeholder-glow'><span class='placeholder w-100 h-100'></span></div>`
  },
  errors: {
    loadError: `load error`
  },
  props: {},
});
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });
applications.forEach(app => {
  registerApplication(app);
});
layoutEngine.activate();

start();
window.addEventListener("NOT-PERMISSION", () => {
  // navigateToUrl(`${ROUTES.HOME}/common/page-not-permission`);
  window.location.href=`${ROUTES.HOME_COMMON}/common/page-not-permission`
}); 
window.addEventListener("NOT-FOUND", () => {
  // navigateToUrl(`${ROUTES.HOME}/common/page-not-found`);
  window.location.href=`${ROUTES.HOME_COMMON}/common/page-not-found`
});